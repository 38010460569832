.search {
    align-items: center;
    display: flex;
    flex: 1;
    flex-direction: row-reverse;
    @include mq(l-940) {
        position: relative;
    }
    &__label {
        @extend .f-s--6;
        flex-shrink: 0;
        line-height: 1;
        margin-right: -$space-half;
        padding: $space-half;
        @include mq(m) {
            padding: $space-half;
        }
        &:hover {
            cursor: pointer;
        }
        &.icon-search {
            margin-bottom: -$space-min;
            &:before {
                font-weight: 900;
            }
        }
    }
    &__input {
        @extend .l-border-box--b;
        background-color: white;
        display: none;
        outline: none;
        padding-left: $space-double + $space;
        padding-right: $space-double * 3;
        z-index: 3;
        @include mq(m) {
            padding-left: $space-double * 3;
            padding-right: $space-double * 3 + $space;
        }
        @include mq(l-940) {
            padding-left: $space-double;
            padding-right: $space-double;
        }
        &-icon {
            display: none;
        }
    }
    &__checkbox {
        display: none;
        &:checked~.search__input,
        &:checked~.search__results {
            display: block;
            position: absolute;
            width: 100%;
            left: 0;
            @include mq(l-940) {
                left: auto;
                right: 0;
                width: 50%;
            }
        }
        &:checked~.search__label {
            @extend .icon-close-2;
            z-index: 5;
            &.icon-close-2 {
                &:before {
                    font-weight: 900;
                }
            }
        }
        &:checked~.search__input-icon {
            display: block;
            @extend .f-s--6;
            left: $space;
            margin-top: $space-min - 3px;
            position: absolute;
            z-index: 5;
            &:before {
                font-weight: 900;
            }
            @include mq(m) {
                left: $space-double * 2;
            }
            @include mq(l-940) {
                left: 50%;
            }
        }
    }
    &__results {
        @extend .l-border-box--shadow;
        display: none;
        top: 75px;
        max-height: 300px;
        overflow-y: scroll;
        background-color: white;
        @include mq(l-940) {
            top: 45px;
        }
        ul {
            padding-inline-start: 0;
            margin-block-start: 0;
        }
        li {
            width: 100%;
            display: flex;
            justify-content: space-between;
            padding: 20px;
            @include mq(m) {
                padding-left: $space-double * 3;
                padding-right: $space-double * 3;
            }
            @include mq(l-940) {
                padding: 20px 20px 20px 30px;
            }
        }
        &--title {
            display: flex;
            width: 60%;
            font-size: $font-size--large;
        }
        &--description {
            display: flex;
            overflow: hidden;
            width: 40%;
            font-size: $font-size--tiny;
        }
    }
}