.c-logo {
  &__claim-svg {
    height: 0px;
    opacity: 0;
    overflow: hidden;
    transition: all 1.6s ease;
    width: 100%;
    svg {
      height: 15px;
    }
  }

  // &:hover {
  //   .c-logo__claim-svg {
  //     height: 20px;
  //     opacity: 1;
  //   }
  // }

}
