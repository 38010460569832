.c-info-project {
	position: relative;
	&__item:hover &__overlay {
		opacity: 1;
		color: $color-text--quaternary;
	}
	&__overlay {
		 display: flex;
    justify-content: center;
    flex-direction: column;
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		margin-bottom:0;
		background-color: rgba(0,0,0,0.5);
		opacity: 0;
	    transition: 0.9s;
	    color: $color-text--quaternary;

	    .l-underline:after {
	    	border: 1px solid $color-text--quaternary;
	    }
	}
	&__info-box {
		margin-bottom: -$space-triple;
		position:relative;
		z-index:5;
  }

  &__link {
     color: $color-text--quaternary;

     .l-underline:after {
       border: 1px solid $color-text--quaternary;
     }
 }
}
