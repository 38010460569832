.c-about__image {
	margin: 0px;

	@include mq(l) {
		margin-left: 25%;	
	}
	
}

.c-about__slider {
	margin: -$space-triple $space-double;
	position: relative;
	background-color: $color-background--primary;

	@include mq(l) {
		position:absolute;
		right: 5%;
		margin-top: -$space-triple;
		width: 45%;

	}
}

.c-about__infobox {
	text-align: left;
	margin-top: -$space-triple;
	padding-top: $space-double;


	@include mq(l) {
		float: left;
		max-width: 50%;
	}
}

.c-about__gallery {
	margin-top: $space-double;

	@include mq (l) {
		display: flex;
		margin-top: $space-triple*3;
	}
}