// TODO: css review

.headline {

  font-family: $font-family--primary;
  text-transform: uppercase;

  &--break {
    margin: $space-half auto;
    border-bottom: 1px solid $color-black;
    width: $headline-break--width;

  }

}

.description-text {
  font-weight: bold;
}

.description2-text {
  font-style: italic;
}

.t-line-height-small {
  line-height: 1pt;
}

.t-uppercase {
  text-transform: uppercase;
}
