.overlay {
	display:block;
	position: fixed;
  	top: 10%;
  	left: 10%;
  	right: 10%;
  	z-index: 2;

  	background-color: $color-white;
  	box-shadow: 0px 0px 20px silver;
  	color:black;


  	p {
  		padding: 0 $space;
  	}

}

.image-container {
  position: relative;
  width:100%;

  img {
    display: block;
    width: 100%;
    height: auto;
  }
}



.image-overlay-text {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  transition: .5s ease;
  background-color: rgba(0,0,0,0.2);

  div {
    color: $color-overlay--primary;
    font-size: 20px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    background-color: $color-transparency;
  }
}

.image-container:hover .image-overlay-text {
  opacity: 1;
}

.c-gallery-item {
  position: relative;
}

.c-gallery-overlay-text {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 0;
  width: 100%;
  overflow: hidden;
  transition: .5s ease;
  background-color: rgba(0,0,0,0.2);

  div {
    color: $color-text--secondary;
    font-size: 20px;

    margin-top:$space-half;
    text-align: left;
    background-color: $color-transparency;

    .highlight {
      color: $color-overlay--primary;
    }

  }

  &.active{
    height: 100%;
    transition: .5s ease;
  }
}

.icon-overlay {
  position: absolute;
  bottom: 0;
  right: 0;
  margin-bottom: -10px;

  background-color: $color-transparency;
  z-index: 5;


  i {
      font-size: 20pt;
  }
}

.hidden {
      display:none;
}

.c-image-overlay {
  overflow: hidden;
  position: relative;

  &__image {
    transition: .3s;
    width: 100%;
  }

  &__caption {
    backface-visibility: hidden;
    background-color: rgba(0,0,0,.6);
    bottom: 0;
    color: $color-text--secondary;
    height: 100%;
    left: 0;
    opacity: 0;
    position: absolute;
    padding: $space;
    top: auto;

    transition: transform .4s ease, opacity .4s .1s;
    width: 100%;
  }

  &__icon {
    bottom: $space/8;
    cursor: pointer;
    position: absolute;
    right: $space-half;
    z-index: 4;

    animation: rotate-back .3s;
    animation-fill-mode: forwards;
    @keyframes rotate-back {
      from {
        transform: rotate(45deg) translate3D(-7%, 0, 0);
      }
      to {
        transform: rotate(0deg) translate3D(0, 0, 0);
      }
    }
  }

  &.is-active {
    .c-image-overlay__image {
      transition: .3s;
      filter: blur(2px);
      transform: scale3d(1.5,1.5,1) translateY(15%);
    }
    .c-image-overlay__caption {
      opacity: 1;

      transition: transform .4s ease, opacity .4s;
      overflow-y: auto;
      overflow-x: hidden;
    }
    .c-image-overlay__icon {
      animation: rotate .3s;
      animation-fill-mode: forwards;
      @keyframes rotate {
        from {
          transform: rotate(0deg) translate3D(0, 0, 0);
        }
        to {
          transform: rotate(45deg) translate3D(-7%, 0, 0);
        }
      }

      &:before {
        color: $color-text--secondary;
      }
    }
  }
}

