.c-header {
  box-shadow:0px 3px 20px rgba(0, 0, 0, 0.16);
  height: 70px;

  @include mq(m) {
    height: 98px;
  }

  @include mq(l) {
    height: 126px;
    transition: height 0.5s 0.25s;
    padding: 0 $space;

  }
}
.shrinked {
  transition: height 0.5s 0.25s;
  height: 50px;

  @include mq(l) {
    height: 80px !important;
  }
}

%abstract-sticky {
  background-color: $color-white;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  z-index:10;
}

.l-sticky {
  @extend %abstract-sticky;

  &--100 {
  	@extend %abstract-sticky;
  	bottom: 0%;
  }
}
