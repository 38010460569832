.l-underline {
  letter-spacing: 1px;
  text-align: center;
  position: relative;
  text-transform: uppercase;
  &:after {
    border: 1px solid $color-text--primary;
    border-width: 0 0 $space/8 0;
    content: '';
    display: block;
    left: 50%;
    position: absolute;
    bottom: -$space;
    transform: translateX(-50%);
    width: $space-triple;
  }
}
