

.l-border {
	
	border: 1px solid $color-border--primary;

	&-clear {
		border: 0;
	}

	@each $oKey, $oValue in $orientations {
		&-#{$oKey} {
			border-#{$oValue}:1px solid $color-border--primary;
		}

	}
	
	&-color--secondary {
		border-color: $color-border--secondary;
	}


}