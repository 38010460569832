$corner-icon: -20px;
$corner-transition: 0.3s;

.c-corner {

  &__inside {
    overflow: hidden;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: relative;
  }

  &__outside {

    &--left {
      overflow: hidden;

      @include mq(l-940) {
        position: relative;
        float: left;
        width: 30%;
      }
    }

    &--right {
      overflow: hidden;

      @include mq(l-940) {
        position: relative;
        float: right;
        width: 30%;
      }
    }

  }

  &__overlay {
    &__text {
      text-align: left;
    }

    &--top-left {
      transition: $corner-transition;
      z-index: 2;
      transform: translate3d(0%, -100%, 0);
      max-height: 0px;
      overflow: hidden;


      @include mq(l-940) {
        transform: translate3d(100%, 100%, 0);
        bottom: 0;
        width: 100%;
      }

      &--active {
        transition: $corner-transition;
        transform: translate3d(0%, 0%, 0);
        max-height: 5000px;
        height: 100%;
      }
    }

    &--top-right {
      transition: $corner-transition;
      z-index: 2;
      transform: translate3d(0%, -100%, 0);
      max-height: 0px;
      overflow: hidden;

      @include mq(l-940) {
        transform: translate3d(-100%, 100%, 0);
        width: 100%;
      }

      &--active {
        transition: $corner-transition;
        transform: translate3d(0%, 0%, 0);
        max-height: 5000px;
        height: 100%;
      }
    }

    &--bottom-right {
      transition: $corner-transition;
      z-index: 2;
      transform: translate3d(0%, -100%, 0);
      max-height: 0px;
      overflow: hidden;

      @include mq(l-940) {
        transform: translate3d(-100%, -100%, 0);
        width: 100%;
      }

      &--active {
        transition: $corner-transition;
        transform: translate3d(0%, 0%, 0);
        max-height: 5000px;
        height: 100%;
      }
    }

    &--bottom-left {
      transition: $corner-transition;
      z-index: 2;
      transform: translate3d(0%, -100%, 0);
      max-height: 0px;
      overflow: hidden;

      @include mq(l-940) {
        transform: translate3d(100%, -100%, 0);
        width: 100%;
      }

      &--active {
        transition: $corner-transition;
        transform: translate3d(0%, 0%, 0);
        max-height: 5000px;
        height: 100%;
      }
    }
  }

  &__image {
    &--left {
      @include mq(l-940) {
        float: right;
        width: 70%;
      }
    }

    &--right {
      @include mq(l-940) {
        float: left;
        width: 70%;
      }
    }

  }

  &__icon {
    animation: rotate-back;
    animation-duration: $corner-transition;
    animation-fill-mode: forwards;

    @keyframes rotate-back {
      from {
        transform: rotate(45deg);
        transform-origin: 50 50;
      }

      to {
        transform: rotate(0deg);
        transform-origin: 50 50;
      }
    }

    &.icon-add-negative {
      height: 40px;
    }


    &--active {
      animation: rotate;
      animation-duration: $corner-transition;
      animation-fill-mode: forwards;

      @keyframes rotate {
        from {
          transform: rotate(0deg);
          transform-origin: 50 50;
        }

        to {
          transform: rotate(45deg);
          transform-origin: 50 50;
        }
      }

      &.icon-add-negative {
        height: 40px;
      }
    }


    &--top-left {
      position: absolute;
      z-index: 3;
      bottom: $corner-icon;
      left: calc((100% - 40px)/2);
      transform: translateX(-19px);

      @include mq(m) {
        bottom: $corner-icon;
        transform: translateX(-19px);
      }

      @include mq(l-940) {
        top: $corner-icon;
        left: $corner-icon + 1px;
        transform: translateX(0px);
      }
    }

    &--top-right {
      position: absolute;
      z-index: 3;
      bottom: $corner-icon;
      left: calc((100% - 40px)/2);
      transform: translateX(-19px);


      @include mq(m) {
        bottom: $corner-icon;
        transform: translateX(-19px);
      }

      @include mq(l-940) {
        top: $corner-icon;
        right: $corner-icon;
        left: auto;
      }
    }

    &--bottom-right {
      position: absolute;
      z-index: 3;
      bottom: $corner-icon;
      left: calc((100% - 40px)/2);
      transform: translateX(-19px);

      @include mq(m) {
        bottom: $corner-icon;
        transform: translateX(-19px);
      }

      @include mq(l-940) {
        bottom: $corner-icon;
        right: $corner-icon;
        top: auto;
        left: auto;
      }
    }

    &--bottom-left {
      position: absolute;
      z-index: 3;
      bottom: $corner-icon;
      left: calc((100% - 40px)/2);
      transform: translateX(-19px);

      @include mq(m) {
        bottom: $corner-icon;
        transform: translateX(-19px);
      }

      @include mq(l-940) {
        bottom: $corner-icon;
        left: $corner-icon;
      }
    }
  }
}
