.c-navigation {
  // mobile navigation
  align-items: center;
  background: none;
  cursor: default;
  height: 0;
  left: 0;
  min-height: 0;
  pointer-events: none;
  top: 0;
  width: 100%;
  z-index: -1;
  > * {
    opacity: 0;
  }


  @include mq(2xl) {
    // desktop navigation
    display: flex;
    font-family: $font-medium;
    height: auto;
    justify-content: flex-end;
    left: auto;
    margin-right: 12%;
    padding: 0;
    pointer-events: auto;
    position: relative;
    z-index: 2;
    > * {
      opacity: 1;
    }
  }

  &__label {
    flex-shrink: 0;
    font-size: 20px;
    line-height: 22px;
    margin-bottom: -$space-min;
    margin-right: -$space-half;
    margin-left: $space;
    order: 2;
    padding: $space-half;
    z-index: 3;
    &:hover {
      cursor: pointer;
    }
    &:before {
      font-weight: 900;
    }
    @include mq(2xl) {
      display: none;
    }
  }

  &__checkbox {
    display: none;
    &:checked ~ .c-navigation {
      background: linear-gradient(to bottom, $color-gradient--primary, $color-gradient--secondary 70%);
      height: 100vh;
      min-height: 100vh;
      overflow-x: auto;
      padding: $space-quadruple 0 $space-triple;
      pointer-events: auto;
      position: absolute;
      z-index: 2;
      > * {
        opacity: 1;
        transition: height 1s, opacity 1s;
      }
    }
  }

  &__item {
    color: $color-text--secondary;
    display: flex;
    padding: $space;
    justify-content: center;

    &:hover {
      background-color: $color-background--tetriary;
      font-weight: bold;
      @include mq(m) {
        background-color: transparent;
        font-weight: bold;
      }
      @include mq(2xl) {
        font-weight: normal;
      }
    }

    @include mq(2xl) {
      color: $color-link--primary;
      padding: 0;
      position: relative;
      &:after {
        content: '';
        height: 1px;
        background-color: $color-link--primary;
        position: absolute;
        top: $space-and-half;
        width: 0;
        left: 50%;
        transition: .2s ease-out all .1s;
      }
      &:hover {
        &:after {
          left: 0;
          width: 100%;
        }
      }
      &:not(:last-child) {
        margin-right: $space-triple;
      }
    }
  }
}

.c-icon-list {
  margin: 0 auto;
  padding: $space $space-double;
  max-width: $page-width-half;

  @include mq(s-370) {
    padding: $space $space-triple $space-double;
  }

  @include mq(2xl) {
    display: none;
  }

  &__item {
    color: $color-text--secondary;
    display: flex;
    flex: 0 0 70px;
    flex-direction: column;
  }

  &__item-icon {
    @include f-s(6);
    border-radius: 100%;
    background-color: rgba(0,0,0,0.2);
    display: flex;
    height: 70px;
    padding: $space-and-half;
    margin-bottom: $space-half;
    width: 70px;
    &:hover {
      background-color: $color-background--tetriary;
    }
    &:before {
      font-weight: 600;
    }
  }

  &__item-text {
    @include f-s(2);
  }
}
