/*****************************
 * Layout Grid
 ****************************/

 .l-grid-row {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.l-container {
  max-width: span(12);
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  padding: 0 15px;
}

.l-container-100 {
  @extend .l-container;
  max-width: span($page-width);
  &--responsive {
    max-width: span($page-width);
    padding: 0;
    @include mq(m) {
      padding: 0 15px;
    }
  }
}

.l-container-80 {
  @extend .l-container;
  max-width: span($page-width-slim);
  &--responsive {
    max-width: span($page-width-slim);
    padding: 0;
    @include mq(m) {
      padding: 0 15px;
    }
  }
}


@for $i from 1 through 12 {
  .l-grid-#{$i} {
    flex: 0 0 span($i);
    max-width: span($i);
    padding: 0 $space;
    width: span($i);
    word-break: break-word;
  }
}

@each $key, $value in $breakpoints {
  @for $i from 1 through 12 {
    @include mq($key) {
      .l-grid-#{$i}\@#{$key} {
        flex: 0 0 span($i);
        max-width: span($i);
        padding: 0 $space;
        width: span($i);
        word-break: break-word;
      }
    }
  }
}

/*****************************
 * Paddings & Margins
 ****************************/
@each $key, $value in $spacelist {
  .l-pad-#{$key} {
    padding: #{$value};
  }
  .l-mar-#{$key} {
    margin: #{$value};
  }

  @each $oKey, $oValue in $orientations {
    .l-pad-#{$key}--#{$oKey} {
      padding-#{$oValue}: #{$value};
    }
    .l-mar-#{$key}--#{$oKey} {
      margin-#{$oValue}: #{$value};
    }
  }
}

@each $bKey, $bValue in $breakpoints {
  @each $key, $value in $spacelist {
    @include mq($bKey) {
      .l-pad-#{$key}\@#{$bKey} {
        padding: #{$value};
      }
      .l-mar-#{$key}\@#{$bKey} {
        margin: #{$value};
      }


      @each $oKey, $oValue in $orientations {
        .l-pad-#{$key}--#{$oKey}\@#{$bKey} {
          padding-#{$oValue}: #{$value};
        }
        .l-mar-#{$key}--#{$oKey}\@#{$bKey} {
          margin-#{$oValue}: #{$value};
        }
      }
    }
  }
}

.l-grid-row {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-left: -$space;
  margin-right: -$space;
}

.l-container {
  max-width: span(12);
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  padding-left: $space;
  padding-right: $space;
}

.l-container-100 {
  @extend .l-container;
  max-width: span($page-width);
  &--responsive {
    max-width: span($page-width);
    padding: 0;
    @include mq(m) {
      padding: 0 $space;
    }
  }
}

.l-container-80 {
  @extend .l-container;
  max-width: span($page-width-slim);
  &--responsive {
    max-width: span($page-width-slim);
    padding: 0;
    @include mq(m) {
      padding: 0 $space;
    }
  }
}

.l-container-l-940 {
  @extend .l-container;
  max-width: span($page-width-l-940);
  &--responsive {
    max-width: span($page-width-l-940);
    padding: 0;
    @include mq(m) {
      padding: 0 $space;
    }
  }
}

.l-container-60 {
  @extend .l-container;
  max-width: span($page-width-half);
  &--responsive {
    max-width: span($page-width-half);
    padding: 0;
    @include mq(m) {
      padding: 0 $space;
    }
  }
}

.l-bg-box--primary {
  background-color: $color-background--primary;
}

.l-bg-box--secondary {
    background-color: $color-background--secondary;
}

.l-bg-box--tetriary {
  background-color: $color-background--tetriary;
}


.l-bg-box--quaternary

{
  background-color: $color-background--quaternary;

}

.l-border-box {
  border: $space/16 solid $color-border--primary;

  &--b {
    border-width: 0 0 $space/16 0;
  }

  &--t {
    border-width: $space/16 0 0 0;
  }

  &--l {
    border-width: 0 0 0 $space/16;
  }

  &--r {
    border-width: 0 $space/16 0 0;
  }

  &--transparent {
    border: $space/16 solid transparent;
  }

  &--primary {
    border-color: $color-border--primary;
  }

  &--secondary {
    border-color: $color-border--secondary;
  }
}

.l-border-box--shadow {
  border: $space/16 solid $color-shadow--primary;
  box-shadow: $space/8 $space/8 $space/8 $color-shadow--primary;
}

.l-drop-shadow {
  box-shadow: 0 0  20px 0 $color-shadow--secondary;
}

/*****************************
 * Clearfix for Floats
 ****************************/
.clearfix {
  display: table;
  clear: both;
}

.l-mar-h-center {
  margin-left: auto;
  margin-right: auto;
}

.l-display-inline-block {
  display:inline-block;

}

.l-overflow-scroll {
  overflow-x: scroll;
}

.active {
  display: inherit !important;
  visibility: visible !important;
}

.overflow-x-hidden {
  overflow-x: hidden;
}

.overflow-y-hidden {
  overflow-y: hidden;
}

%abstract-sticky {
  background-color: $color-white;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  z-index:10;
}

.l-sticky {
  @extend %abstract-sticky;

  &--100 {
  	@extend %abstract-sticky;
  	bottom: 0%;
  }
}
