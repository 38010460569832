.c-partnerdetail 
{
	top:0;
	left:0;
	right: 0;
	bottom: 0;
	position: fixed;
	z-index: 1;
	overflow-y: scroll;
	overflow-x: hidden;

	&__icon {
		vertical-align: bottom;
	}

	&__wrap {
		padding: 0 16px;
	}

	&__image {
		text-align: center;

		@include mq(l-940) {
			text-align: left;
		}
	}
}

.nav-text
{
 text-align:left;
}

.iconstyle {
    position: absolute;
    right: 5%;
    top: 16%;
    bottom: auto;
    font-size: 50px;
}