.c-logo,
.c-logo__claim {
  color: $color-link--primary;
  display: inline-block;
  max-width: 150px;
  min-width: 150px;
  margin: auto;
  @include mq(m) {
    padding-left: $space-min / 4;
    max-width: 180px;
    min-width: 180px;
  }
  @include mq(l) {
    padding-left: $space-min / 4;
    max-width: 200px;
    min-width: 200px;
  }
  @include mq(xl) {
    max-width: 260px;
    min-width: 260px;
  }
}
