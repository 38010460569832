.c-octa-pillar {


  .card {
    position: relative;
    height: 200px;
    -webkit-perspective: 1000;
    perspective: 1000;
    transform-style: preserve-3d;

    &__side {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 200px;
      -webkit-backface-visibility: hidden;
      -webkit-perspective: 1000;
      -webkit-transition: all 1s cubic-bezier(.5,1,.5,1);
      backface-visibility: hidden;
      perspective: 1000;
      transition: all 1s cubic-bezier(.5,1.3,.5,1.3);
      visibility: visible;

      &--front {
        background-color: #FFF;
        transform: rotateY(0);
      }

      &--back {
        background-color: $color-grey;
        transform: rotateY(180deg);
      }
    }

    &:hover {
      .card__side--front {
        transform: rotateY(-180deg);
        z-index: -1;
      }
      .card__side--back {
        transform: rotateY(0);
        z-index: 5;
      }
    }

    &__icon {
      position:relative;
      width: 100%;
      max-width: 125px;
      padding: 5px 20px;
    }

    &__icon-partner {
      display: block;
      width: auto;
      max-width: 250px;
      max-height: 100%;
      padding: 5px;
      margin: auto;
    }

    &__text-partner {
      margin: auto;

      p {
        margin: auto;
      }
    }
  }
}
