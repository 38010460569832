.c-accordion__header {
  border-bottom: 1px solid  ;
  border-color: $color-grey;
  margin: 0px $space 0px $space;
  padding: $space;
  position: relative;
  transition: border .5s;
  text-transform: uppercase;
  color: $color-grey;
}

.c-accordion__items {
  margin-top: 0px;
  padding: 0;
}

.c-accordion__item {
    padding-left:0;
    margin: 0px $space 0px $space;
    padding: $space;
    border-bottom: 1px solid  ;
    border-color: $color-grey;
    transition: border .5s;

    .c-accordion__content {
      display: none;
      visibility: hidden;
    }

    &.active {
      .c-accordion__content{
        display: inherit;
        visibility: visible;
      }
    }
}
  .c-accordion__headline {
    cursor: pointer;
    position: relative;
    transition: border .5s;
    color: $color-grey;
    margin: 0;
    .c-accordion__status {
      font-size: $font-size--medium-large;
      height: 24px;
      position: absolute;
      right: 0px;
      text-align: right;
      top: 50%;
      transform: translateY(-30%);
      width: 5%;
    }
  }

  .c-accordion__headline {
    &.active {
      border-width: 0px;
    }
  }

.c-accordion__date {
  display: none;
  visibility: hidden;

  @include mq(xl) {
    position: absolute;
    right: 15px;
    text-align: left;
    top: 15%;
    transform: translateY(-100%);
    width: 35%;
    visibility: visible;
    display: block;
  }
}

.c-accordion__date--mobile {
  display: block;
  visibility: visible;

  @include mq(xl) {
    display: none;
    visibility: hidden;
  }
}

.c-accordion__calendar {
  display: none;
  visibility: hidden;

  @include mq(m) {
    position: absolute;
    right: 20px;
    text-align: left;
    top: 15%;
    width: 35%;
    display: block;
    visibility: visible;
  }
}

.c-accordion__list {
    margin-top: 5px;margin-bottom: 5px;
}

.c-accordion__mail {
    padding: 12px;
}
