/**
 * @license
 * MyFonts Webfont Build ID 3276735, 2016-08-31T05:00:28-0400
 *
 * The fonts listed in this notice are subject to the End User License
 * Agreement(s) entered into by the website owner. All other parties are
 * explicitly restricted from using the Licensed Webfonts(s).
 *
 * You may obtain a valid license at the URLs below.
 *
 * Webfont: DINNextRoundedLTPro-Regular by Linotype
 * URL: http://www.myfonts.com/fonts/linotype/din-next-rounded/pro-rounded-regular/
 *
 * Webfont: DINNextRoundedLTPro-Bold by Linotype
 * URL: http://www.myfonts.com/fonts/linotype/din-next-rounded/pro-rounded-bold/
 *
 * Webfont: DINNextRoundedLTPro-Light by Linotype
 * URL: http://www.myfonts.com/fonts/linotype/din-next-rounded/pro-rounded-light/
 *
 * Webfont: DINNextRoundedLTPro-Medium by Linotype
 * URL: http://www.myfonts.com/fonts/linotype/din-next-rounded/pro-rounded-medium/
 *
 *
 * License: http://www.myfonts.com/viewlicense?type=web&buildid=3276735
 * Licensed pageviews: 250,000
 * Webfonts copyright: Copyright &#x00A9; 2008 - 2010 Linotype GmbH, www.linotype.com. All rights reserved. This font software may not be reproduced, modified, disclosed or transferred without the express written approval of Linotype GmbH.
 *
 * © 2016 MyFonts Inc
*/

$font-path : "../fonts";

/* @import must be at top of file, otherwise CSS will not work */

@font-face {
  font-family: 'DINNextRoundedLTPro-Regular';
  src: url('#{$font-path}/31FFBF_0_0.eot');
  src: url('#{$font-path}/31FFBF_0_0.eot?#iefix') format('embedded-opentype'),
       url('#{$font-path}/31FFBF_0_0.woff2') format('woff2'),
       url('#{$font-path}/31FFBF_0_0.woff') format('woff'),
       url('#{$font-path}/31FFBF_0_0.ttf') format('truetype');
}

@font-face {
  font-family: 'DINNextRoundedLTPro-Bold';
  src: url('#{$font-path}/31FFBF_1_0.eot');
  src: url('#{$font-path}/31FFBF_1_0.eot?#iefix') format('embedded-opentype'),
  url('#{$font-path}/31FFBF_1_0.woff2') format('woff2'),
  url('#{$font-path}/31FFBF_1_0.woff') format('woff'),
  url('#{$font-path}/31FFBF_1_0.ttf') format('truetype');
}

@font-face {
  font-family: 'DINNextRoundedLTPro-Light';
  src: url('#{$font-path}/31FFBF_2_0.eot');
  src: url('#{$font-path}/31FFBF_2_0.eot?#iefix') format('embedded-opentype'),
  url('#{$font-path}/31FFBF_2_0.woff2') format('woff2'),
  url('#{$font-path}/31FFBF_2_0.woff') format('woff'),
  url('#{$font-path}/31FFBF_2_0.ttf') format('truetype');
}

@font-face {
  font-family: 'DINNextRoundedLTPro-Medium';
  src: url('#{$font-path}/31FFBF_3_0.eot');
  src: url('#{$font-path}/31FFBF_3_0.eot?#iefix') format('embedded-opentype'),
  url('#{$font-path}/31FFBF_3_0.woff2') format('woff2'),
  url('#{$font-path}/31FFBF_3_0.woff') format('woff'),
  url('#{$font-path}/31FFBF_3_0.ttf') format('truetype');
}

/* Webfont: Lato-Heavy */
@font-face {
  font-family: 'LatoWebHeavy';
  src: url('#{$font-path}/Lato-Heavy.eot'); /* IE9 Compat Modes */
  src: url('#{$font-path}/Lato-Heavy.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
        url('#{$font-path}/Lato-Heavy.woff2') format('woff2'), /* Modern Browsers */
        url('#{$font-path}/Lato-Heavy.woff') format('woff'), /* Modern Browsers */
        url('#{$font-path}/Lato-Heavy.ttf') format('truetype');
  font-style: normal;
  font-weight: normal;
  text-rendering: optimizeLegibility;
}

/* Webfont: Lato-Bold */
@font-face {
  font-family: 'LatoWebBold';
  src: url('#{$font-path}/Lato-Bold.eot'); /* IE9 Compat Modes */
  src: url('#{$font-path}/Lato-Bold.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
        url('#{$font-path}/Lato-Bold.woff2') format('woff2'), /* Modern Browsers */
        url('#{$font-path}/Lato-Bold.woff') format('woff'), /* Modern Browsers */
        url('#{$font-path}/Lato-Bold.ttf') format('truetype');
  font-style: normal;
  font-weight: normal;
  text-rendering: optimizeLegibility;
}

/* Webfont: Lato-Light */
@font-face {
  font-family: 'LatoWebLight';
  src: url('#{$font-path}/Lato-Light.eot'); /* IE9 Compat Modes */
  src: url('#{$font-path}/Lato-Light.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
        url('#{$font-path}/Lato-Light.woff2') format('woff2'), /* Modern Browsers */
        url('#{$font-path}/Lato-Light.woff') format('woff'), /* Modern Browsers */
        url('#{$font-path}/Lato-Light.ttf') format('truetype');
  font-style: normal;
  font-weight: normal;
  text-rendering: optimizeLegibility;
}

/* Webfont: Lato-LightItalic */
@font-face {
  font-family: 'LatoWebLight';
  src: url('#{$font-path}/Lato-LightItalic.eot'); /* IE9 Compat Modes */
  src: url('#{$font-path}/Lato-LightItalic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
        url('#{$font-path}/Lato-LightItalic.woff2') format('woff2'), /* Modern Browsers */
        url('#{$font-path}/Lato-LightItalic.woff') format('woff'), /* Modern Browsers */
        url('#{$font-path}/Lato-LightItalic.ttf') format('truetype');
  font-style: italic;
  font-weight: normal;
  text-rendering: optimizeLegibility;
}

/* Webfont: Lato-Medium */
@font-face {
    font-family: 'LatoWebMedium';
    src: url('#{$font-path}/Lato-Medium.eot'); /* IE9 Compat Modes */
    src: url('#{$font-path}/Lato-Medium.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('#{$font-path}/Lato-Medium.woff2') format('woff2'), /* Modern Browsers */
         url('#{$font-path}/Lato-Medium.woff') format('woff'), /* Modern Browsers */
         url('#{$font-path}/Lato-Medium.ttf') format('truetype');
    font-style: normal;
    font-weight: normal;
    text-rendering: optimizeLegibility;
}

/* Webfont: Faktum */
@font-face {
  font-family: Faktum;
  src: url(../fonts/Faktum-Regular.eot);
  src: url(../fonts/Faktum-Regular.eot?#iefix) format("embedded-opentype"),
       url(../fonts/Faktum-Regular.woff2) format("woff2"),
       url(../fonts/Faktum-Regular.woff) format("woff"),
       url(../fonts/Faktum-Regular.ttf) format("truetype");
  font-weight: 400;
  font-style: normal
}

/* Webfont: Faktum-Medium */
@font-face {
  font-family: Faktum-Medium;
  src: url(../fonts/Faktum-Medium.eot);
  src: url(../fonts/Faktum-Medium.eot?#iefix) format("embedded-opentype"),
       url(../fonts/Faktum-Medium.woff2) format("woff2"),
       url(../fonts/Faktum-Medium.woff) format("woff"),
       url(../fonts/Faktum-Medium.ttf) format("truetype");
  font-weight: 400;
  font-style: normal
}

/* Webfont: Faktum-Bold */
@font-face {
  font-family: Faktum-Bold;
  src: url(../fonts/Faktum-Bold.eot);
  src: url(../fonts/Faktum-Bold.eot?#iefix) format("embedded-opentype"),url(../fonts/Faktum-Bold.woff2) format("woff2"),url(../fonts/Faktum-Bold.woff) format("woff"),url(../fonts/Faktum-Bold.ttf) format("truetype");
  font-weight: 400;
  font-style: normal
}
