.internal__header {
  background-color: $color-white;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  z-index: 10;
}
.internal__container {
  position: absolute;
  top: 100px;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: scroll;
  background-repeat: no-repeat;
  background-position: 50% 50%;
}

.internal__menu {
 display: flex;
 font-size: 22px;
 margin-top: 32px;
 
 ul {
   display: flex;
   list-style: none;
   padding: 16px;
   margin-top: 16px;
   li, li a {
     color: $color-white;
     opacity: .75;     
     cursor: pointer;
     transition: 0.25ms;
     text-decoration: none;
     white-space: nowrap;
     font-weight: 700;
     &:hover {
       opacity: 1;
     }
     a {
       display: flex;
       align-items: center;
       height: 100%;
       width: 100%;      
     }      
   }
   li {
     padding-right: 32px;
     display: flex;
     width: 100%;
     &::before {
       content: '';
       width: 0; 
       height: 0; 
       border-top: 5px solid transparent;
       border-bottom: 5px solid transparent;
       border-left: 5px solid $color-white;
       position: absolute;
       right: 8px;
       top: 45%;
     }
   }
   .internal__link {
     &::before {
       padding-right: 0;
       display: none;
     }      
   }
 }
 > ul {
   display: flex;
   align-items: center;
   flex-wrap: wrap;
   flex-direction: column;
   
   li {
     position: relative;
     margin: 0 8px;
     padding: 32px;
     display: flex;
     width: 100%;

     ul {
       display: flex;
       flex-direction: column;
       visibility: hidden;
       opacity: 0;        
       padding: 0;
       min-width: 100px;
       position: absolute;
       top: 0;
       left: 100%;
       transition: 0.33s;
       transition-delay: 0.33s;
       transform: translateY(-25%);
       li {
         margin: 0;
         padding: 32px;
         display: flex;
         align-items: center;
         justify-content: flex-start;
         height: 30px;
         padding-right: 40px;
         &::before {
           width: 0; 
           height: 0; 
           border-top: 5px solid transparent;
           border-bottom: 5px solid transparent;
           border-left: 5px solid $color-white;
         }
         ul {
           top: 0;
           left: 100%;
           transform: translateY(0)
         }
       }
     }
     &:hover {
       > ul {
         opacity: 1;
         visibility: visible;
         transition-delay: 0ms;
       }
     }
   }
 }
}