/*****************************
 * Font Family
 ****************************/
.f--primary {
  font-family: $font-family--primary;
}

.f--secondary {
  font-family: $font-family--secondary;
}

.f--tetriary {
  font-family: $font-family--tetriary;
}

/*****************************
 * Font Color
 ****************************/
.f-c--primary {
  color: $color-grey--dark;
}

/*****************************
 * Font Sizes & Line Heights
 ****************************/
@each $key,
$value in $sizes {
  .f-s--#{$key} {
    @include f-s($key);
  }

  .f-lh--#{$key} {
    @include f-lh($key);
  }
}

@each $bKey,
$bValue in $breakpoints {

  @each $key,
  $value in $sizes {
    @include mq($bKey) {
      .f-s--#{$key}\@#{$bKey} {
        @include f-s($key);
      }

      .f-lh--#{$key}\@#{$bKey} {
        @include f-lh($key);
      }
    }
  }
}

/*****************************
 * Font Weight
 ****************************/
.f-w--normal {
  font-family: $font-family--primary;
}

.f-w--medium {
  font-family: $font-medium;
}

.f-w--bold {
  font-family: $font-bold;
}

b,
strong {
  font-family: $font-bold;
}

@each $key,
$value in $sizes {
  .f-w--#{$key*100} {
    font-weight: $key * 100;
  }
}

/*****************************
 * Font Alignment
 ****************************/
.f-a--right {
  text-align: right;
}

.f-a--left {
  text-align: left;
}

.f-a--center {
  text-align: center;
}

.f-a--justify {
  text-align: justify;
}

/*****************************
 * Font Letter Spacing
 ****************************/
.f-ls--1 {
  letter-spacing: $space-half/8;
}

.f-ls--2 {
  letter-spacing: $space-half/5;
}

.f-ls--3 {
  letter-spacing: $space-half/4;
}

.f--singleline {
  white-space: nowrap;
}
