.c-macbook {
    position: relative;
    &__screen {
        position: absolute;
        overflow-y: scroll;
        top: 17%;
        left: 24%;
        right: 24%;
        bottom: 19.9%;
        @include mq(m) {
            top: 17%;
            left: 23%;
            right: 23%;
            bottom: 19.9%;
        }
        @include mq(l) {
            top: 17%;
            left: 22.25%;
            right: 22.25%;
            bottom: 19.9%;
        }
    }
}