.c-cookiebar {
  background-color: $color-background--transparent;
  bottom: 0;
  color: $color-text--primary;
  flex-direction: column;
  margin: 0;
  position: fixed;
  z-index: 10;
  width: 100%;
  justify-content: center;

  @include mq(l) {
    flex-direction: row;
    align-items: center;
  }

  .cookiebar-flex {
    display: block;

    @include mq(l) {
      display: flex;
    }
  }
}
