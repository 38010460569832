@mixin mq($breakpoint) {
  @if map-has-key($breakpoints, $breakpoint) {
    @media (min-width: #{map-get($breakpoints, $breakpoint)}) {
      @content;
    }
  }
  @else {
    @warn "Unfortunately, no value could be retrieved from `#{$breakpoint}`. "
        + "Please make sure it is defined in `$breakpoints` map.";
  }
}

@mixin mqMax($breakpoint) {
  @if map-has-key($breakpoints, $breakpoint) {
    $width : map-get($breakpoints, $breakpoint) - 1px;
    @media (max-width: #{$width}) {
      @content;
    }
  }
  @else {
    @warn "Unfortunately, no value could be retrieved from `#{$breakpoint}`. "
        + "Please make sure it is defined in `$breakpoints` map.";
  }
}

@mixin f-s($size) {
  @if map-has-key($sizes, $size) {
    $font-size : map-get($sizes, $size);
    font-size: $font-size;
  }
}

@mixin f-lh($size) {
  @if map-has-key($line-heights, $size) {
    $line-height : map-get($line-heights, $size);
    line-height: $line-height;
  }
}
