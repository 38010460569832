@import 'organisms/accordion.scss';

@import 'organisms/footer.scss';
 
@import 'organisms/slider.scss';

@import 'organisms/header.scss';

@import 'organisms/info-media-component.scss';

@import 'organisms/partner-gallery.scss';

@import 'organisms/info-project-component.scss';

@import 'organisms/octa-pillar.scss';

@import 'organisms/img-overlay.scss';

@import 'organisms/macbook.scss';

@import 'organisms/corner-component.scss';

@import 'organisms/internal.scss';

