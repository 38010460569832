h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: inherit;
  line-height: inherit;
  margin: 0;
}
