 $border-button-size: 2px;
 /*****************************
 * Buttons
 ****************************/


.btn {
  @include f-s(2);
  border: none;
  cursor: pointer;
  display: inline-block;
  justify-content: center;
  font-family: $font-bold;
  letter-spacing: $space-half/4;
  padding: $space;
  padding-left: $space-triple;
  padding-right: $space-triple;
  text-align: center;
  text-transform: uppercase;
  transition: all .1s ease-in;
  white-space: nowrap;
}

.btn--default {
  background-color: $color-background-button--primary;
  color: $color-text--secondary;
  &:hover, &:focus, &:active {
    background-color: darken($color-background-button--primary, 10%);
  }
}

.btn--primary {
  background-color: $color-background-button--primary;
  color: $color-text--secondary;
  &:hover, &:focus, &:active {
    background-color: darken($color-background-button--primary, 10%);
  }
}

.btn--secondary {
  background-color: $color-background-button--secondary;
  color: $color-text--secondary;
  &:hover, &:focus, &:active {
    background-color: darken($color-background-button--secondary, 10%);
  }
}

.btn--primary-outline {
  background-color: transparent;
    border: $border-button-size solid;
    color: $color-background-border--primary;
    &:hover {
    background-color: $color-background-button--primary;
    color: $color-text--secondary;
    border: $border-button-size solid $color-background-border--primary;
    }
    &:focus, &:active {
      background-color: darken($color-background-button--primary, 10%);
      color: $color-text--secondary;
      border: $border-button-size solid $color-background-border--primary;
    }

 }

.btn--secondary-outline {
  background-color: transparent;
    border: $border-button-size solid;
    color: $color-background-border--secondary;
    &:hover {
    background-color: $color-background-button--secondary;
    color: $color-text--secondary;
    border: $border-button-size solid $color-background-button--secondary;
      &:active {
      background-color: darken($color-background-button--secondary, 10%);
      border: $border-button-size solid $color-background-button--secondary;
      }
    }
 }

%abstract-btn-text {
  background-color: none;
  color: $color-link--primary;
  display: inline-block;
  padding-right: $space-double;
  &:hover, &:focus, &:active {
    color: lighten($color-link--primary, 10%);
  }
  &:after {
    content: ">>";
    letter-spacing: 0;
    padding-left: $space/8;
  }
}

.btn-text {
  @extend %abstract-btn-text;

  &--primary {
    @extend %abstract-btn-text;
    color: $color-link--secondary;
  }

  &--secondary {
    @extend %abstract-btn-text;
    color: $color-link--tetriary;
  }

  &--tetriary {
    @extend %abstract-btn-text;
    color: $color-text--tetriary;
  }

  &--quaternary {
    @extend %abstract-btn-text;
    color: $color-link--quaternary;
  }

  &--quinary {
    @extend %abstract-btn-text;
    color: $color-link--quinary;
  }

  &--senary {
    @extend %abstract-btn-text;
    color: $color-link--senary;
  }
}
.btn--disabled {
  cursor: default;
  opacity: .5;
  pointer-events: none;
}
