.hero {
  position: relative;
  overflow: hidden;

  &:before,
  &:after {
    border-bottom: 10px solid var(--color-var);
    bottom: 0;
    content: '';
    height: 0;
    position: absolute;
    width: 50%;
    z-index: 1;
  }
  &:before {
    border-left: 0 solid transparent;
    border-right: 20px solid transparent;
    left: 0;
  }
  &:after {
    border-left: 20px solid transparent;
    border-right: 0 solid transparent;
    right: 0;
  }

  &.responsive-background-image {
    height: 400px;
    width: 100%;
    @include mq(m) {
      height: 500px;
    }
    @include mq(xl) {
      height: 600px;
      // padding-bottom: 30%;
    }
  }
}

.hero-overlay {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.5);

  &__blured {
    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(5px);
  }

  &__text-wrapper {
    width: 75%;
    margin-left: auto;
    margin-right: auto;
  }
}

.hero-icon {
  position: absolute;
  color: white;
  font-size: 20px;
  z-index: 5;
  bottom: 50px;
  left: 50%;
  transform: translate(-50%, 0);
  width: 20px;

  @include mq(m) {
    font-size: 40px;
    bottom: 80px;
    width: 40px;
  }

  .bounce {
    position: absolute;
    animation: bounce 2s infinite;
    width: 100%;
  }

  @keyframes bounce {
    0%,
    20%,
    50%,
    80%,
    100% {
      top: 0;
    }
    40% {
      top: 20px;
    }
    60% {
      top: 10px;
    }
  }
}

.hero-coord {
  position: absolute;
  text-align: center;
  bottom: 25%;
  left: 0;
  right: 0;
  @include f-s(6);

  @include mq(m) {
    @include f-s(7);
  }

  @include mq(l) {
    @include f-s(8);
  }

  @include mq(xl) {
    bottom: 15%;
    @include f-s(9);
  }

  @include mq(2xl) {
    bottom: 25%;
    @include f-s(9);
  }
}

.hero-banner {
  position: relative;

  @include mq(m) {
  position: absolute;
  display: flex;
  flex-flow: wrap-reverse;
  flex-direction: column;
  right: 0px;
  left: auto;
  top: 5%;
  min-width: 200px;
  max-width: 600px;
  }

  @include mq(xl) {
    min-width: 400px;
    max-height: 1000px;
  }
}
