img {
  height: auto;
  max-width: 100%;
  width: 100%;
}

figure {
  margin: 0;
}

.responsive-background-image {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}

.responsive-background-image picture {
  display: none;
}
