
.c-info-media {
  overflow: hidden;

	&__image {
		margin: 0px;

		@include mq(l) {
			margin-left: 25%;
		}

		img {min-width: 100%;}
	}

	&__slider {
		margin: -$space-triple $space;
		position: relative;
		background-color: $color-background--primary;

		@include mq(l) {
			position:absolute;
			right: 5%;
			margin-top: -$space-triple;
			width: 45%;
    }
	}

	&__infobox {
		text-align: left;
		margin-top: -$space-triple;
		padding-top: $space-double;

		@include mq(l) {
			float: left;
			max-width: 50%;
      padding-right: $space-double;
    }
		@include mq(2xl) {
      padding-left: $space-double;
    }
		@include mq(3xl) {
      padding-left: $space-triple;
    }
		@include mq(4xl) {
      padding-left: $space * 8;
      padding-right: $space * 8;
    }
	}
}
