// TODO: remove static values
.icon-preview {
  padding: 30px;
  span[class*="icon"],
  span[class*="svg"] {
    @include f-s(7);
    display: inline-block;
    margin: 10px;
    &:hover, 
    &:focus {
      color: $color-green;
      transition: 0.25s;
    }
  }
}