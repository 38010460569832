// TODO: code review

.text-slide-container {
  float:none;
  position:relative;
  min-width:60%;
  max-width:100%;
  max-height: 250px;
  border: 1px solid black;
  margin: -5% 5%;
}

.info-box {
	text-align: center;
  padding: $space-double;
  @include mq(l) {
    @include f-s(4);
    padding: $space-triple;
  }
  @include mq(xl) {
    @include f-s(5);
  }
}

.info-box-services {
  margin-top: -81px;
  background: white;
  text-align: left;
  position: relative;
}

.services-list {
    padding: 0;
    line-height: 1.8;
    margin-top: 0px;

    @include mq(l) {
      margin-left: 50px;
      margin-top: 50px;
    }

    @include mq(xl) {
      margin-left: 50px;
      margin-top: 50px;
    }
}

.services-list__item {
  position: relative;
  padding-left: 20px;

  &::before {
    position: absolute;
    left: 0;
    top: 2px;
  }
}

.list-dashed {
  padding-left: 19px;
  text-indent: -19px;

  &::before {
    content: '– ';
    text-indent: -15px;
    font-weight: bold;
    font-size: large;
  }

}

.info-box-services-banner {
    text-align: center;
    background-color: $color-orange;
    height: 65px;
    width: 200px;
}

.info-box-services-banner-text {
transform: translateY(-8px);
}

.banner {
  p {
    line-height: 10px;
    color: $color-white;

    @include mq(s) {
      line-height: 18px;
    }
    @include mq(m) {
      line-height: 10px;
    }

    &.title {
      font-weight: bold;
    }
  }
  a {
    color:white !important;
    text-decoration: underline;
  }
}

.banner--primary {
  @extend .banner;
  background-color: $color-green;
}

.banner--secondary {
  @extend .banner;
  background-color: $color-orange;
}

.banner--tertiary {
  @extend .banner;
  background-color: $color-grey--light;
}

.l-box--relative {
  position: relative;
}
