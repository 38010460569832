/*****************************
 * Font Family
 ****************************/
$font-family--fallback:                 'Helvetica', 'Arial', sans-serif;
$font-family--primary:                  'Faktum', $font-family--fallback;
$font-family--secondary:                'DINNextRoundedLTPro-Light', $font-family--fallback;
$font-family--tetriary:                 'DINNextRoundedLTPro-Bold', $font-family--fallback;

/*****************************
 * Font Styles
 ****************************/

$font-medium:                           'Faktum-Medium', $font-family--fallback;
$font-bold:                             'Faktum-Bold', $font-family--fallback;

/*****************************
 * Font Sizes
 ****************************/
$font-size--extreme:                    100px;
$font-size--huge:                       50px;
$font-size--big:                        40px;
$font-size--medium-big:                 32px;
$font-size--very-large:                 22px;
$font-size--large:                      20px;
$font-size--medium-large:               18px;
$font-size--medium:                     16px;
$font-size--small:                      14px;
$font-size--tiny:                       11px;

$font-ios-form:                         16px;

/*****************************
 * Font Sizes
 ****************************/
$base-line-height--extreme:             100px;
$base-line-height--huge:                50px;
$base-line-height--big:                 40px;
$base-line-height--very-large:          24px;
$base-line-height--large:               20px;
$base-line-height--medium-large:        18px;
$base-line-height--medium:              16px;
$base-line-height--small:               14px;
$base-line-height--tiny:                11px;

/*****************************
 * Map for @mixin f-s mixin
 ****************************/
$sizes: (
  10:                                   $font-size--medium-big,
  9:                                    $font-size--extreme,
  8:                                    $font-size--huge,
  7:                                    $font-size--big,
  6:                                    $font-size--very-large,
  5:                                    $font-size--large,
  4:                                    $font-size--medium-large,
  3:                                    $font-size--medium,
  2:                                    $font-size--small,
  1:                                    $font-size--tiny
);

$line-heights: (
  9:                                    $base-line-height--extreme,
  8:                                    $base-line-height--huge,
  7:                                    $base-line-height--big,
  6:                                    $base-line-height--very-large,
  5:                                    $base-line-height--large,
  4:                                    $base-line-height--medium-large,
  3:                                    $base-line-height--medium,
  2:                                    $base-line-height--small,
  1:                                    $base-line-height--tiny
);
