.c-img-overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 4;

  background-color: rgba(0, 0, 0, 0.5);
  padding-top: $space;
  padding-left: $space;
  transition: 0.5s;
  opacity: 0;

  &--active {
    animation-name:layover;
    animation-duration:0.5s;
    animation-fill-mode: forwards;
    overflow-y: auto;
    overflow-x: hidden;

    @keyframes layover {
      0% {
        opacity: 0;
      }

      100% {
        opacity: 1;
      }
    }

  }

  &__short-title {
    color: $color-green;
    text-align: left;
    @include f-s(1);
    font-family: $font-family--primary;

    @include mq(m) {
      margin-top: 5%;
      margin-left: 10%;
    }

    @include mq(l) {
      margin-top: 10%;
      margin-left: 10%;
    }

  }

  &__title {
    color: $color-text--secondary;
    text-align: left;
    @include f-s(1);
    @extend .f-w--medium;
    @extend .f-ls--2;

    @include mq(m) {
      @include f-s(4);
      margin-left: 10%;
    }

    @include mq(l) {
      @include f-s(6);
      margin-left: 10%;
    }

    @include mq(l-940) {
      @include f-s(7);
      margin-left: 10%;
    }

  }

  &__description {
    color: $color-text--secondary;
    text-align: left;
    @include f-s(1);
    max-width: 75%;
    @extend .f-w--medium;
    @extend .f-ls--2;

    @include mq(m) {
      @include f-s(3);
      margin-top: 5%;
      margin-left: 10%;
      max-width: 45%;
    }

    @include mq(l) {
      @include f-s(5);
      margin-top: 10%;
      margin-left: 10%;
      max-width: 40%;
    }

    @include mq(l-940) {
      @include f-s(6);
      margin-top: 10%;
      margin-left: 10%;
      max-width: 35%;
    }
  }
}

