html {
  font-size: $font-size--medium;
  &.no-scroll {
    overflow: hidden;
  }
}

body {
  font-family: $font-family--primary;
  font-size: 1rem;
  color: $color-text--primary;
  line-height: normal;
  overflow-y: scroll;

  padding-top: 70px;

  @include mq(m) {
    padding-top: 98px;
  }

  @include mq(l) {
    padding-top: 126px;
  }
}
