 /* Font Colors
 ****************************/
.f-c--primary {
    color: $color-text--primary;
}

.f-c--secondary {
    color: $color-text--secondary;
}

.f-c--tetriary {
    color: $color-text--tetriary;
}

.f-c--quaternary {
	color: $color-text--quaternary;
}

.f-c--quinary {
	color: $color-text--quinary;
}
