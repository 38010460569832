.c-partner-gallery {
    filter: grayscale(100%);

    &:hover {
      filter: grayscale(0%);
      cursor: pointer;
    }
  }

  .c-gallery-filter {
    opacity: 1;
  }

  .clear {
    color: $color-orange !important;
    display: none;

    & .active {
        display: block;
    }
  }

  .filter-icon {
    color: $color-orange !important;
  }

  .element-item {
    position: relative;
    float: left;
    padding: 0 $space $space 0;
    margin-bottom: $space-double;
    word-break: break-word;
    flex: 0 0 span(12);
    max-width: span(12);
    padding: 0;
    width: span(12);
    @include mq(m) {
      flex: 0 0 span(6);
      max-width: span(6);
      padding: 0 $space;
      width: span(6);
    }
    @include mq(l) {
      flex: 0 0 span(4);
      max-width: span(4);
      padding: 0 $space;
      width: span(4);
    }
  }

  .c-gallery-filter--dropdown {
    display: inline-block;
    width: 180px;
    height: 30px;
    position: relative;
    cursor: pointer;
  }

  .c-gallery-filter--dropdown-label {
    height: 100%;
    width: 100%;
    padding: 0 $space;
    display: block;
    cursor: pointer;
    z-index: 2;
    position: relative;
    text-align: left;
  }

  .c-gallery-filter--dropdown-icon {
    font-size: $font-size--very-large;
    color: $color-orange;
  }

  .c-gallery-filter--dropdown-button {
    vertical-align: super;
    font-size: $font-size--medium;
    padding: 0 20px;
  }

  .c-gallery-filter--dropdown-content {
    position: absolute;
    top: 0;

    display: block;
    z-index: 1;
    height: 0;
    width: auto;
    padding-top: 30px;

    i{
        display:none;
        z-index: 1;
        visibility: hidden
    };

    li{
      font-size: $font-size--small;
    }
  }

  .c-gallery-filter--dropdown-content:hover ~ .c-gallery-filter--dropdown-label,
  .c-gallery-filter--dropdown-label:hover {
    background: #fff;
  }

  .c-gallery-filter--dropdown-content:hover ~ .c-gallery-filter--dropdown-label {
    z-index: 0;
  }

  .c-gallery-filter--dropdown:active .c-gallery-filter--dropdown-content {
    height: auto;
    width: auto;
    z-index: 5;
  }

  .c-gallery-filter--dropdown-content:hover {
    height: auto;
    width: auto;
    margin: 0;
    z-index: 5;

    .is-checked i{
    display:inline-block;
    visibility: visible;
    position: absolute;
    right: 5%;
    }
  }

  .c-gallery-filter--dropdown-content ul {
    background: $color-grey--light;
    padding: 0;
    overflow: hidden;
    height: 100%;

  }

  .c-gallery-filter--dropdown-content ul a {
    text-decoration: none;
  }

  .c-gallery-filter--dropdown-content li:hover {
    background: #f2f2f2;
    color: #333;
  }

  .c-gallery-filter--dropdown-content li {
    text-align: left;
    color: #888;
    padding: $space 100px $space $space;
  }
