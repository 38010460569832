
footer {
  position: relative;
}

.textunderlined{
color: tetriary;
text-decoration: underline;
}

.textunderlined:hover{
  background: $color-grey;
}

.c-footer {
  position: relative;
  margin-top: $space-and-half;
  background-color: $color-grey;
  padding-left: 0;
  padding-right: 0;
  @include mq(l) {
    padding-left: $space;
    padding-right: $space;

  }

  &__box {
    margin-top: #{$space * 10};
    padding-top: #{$space * 13};
    padding-bottom: $space-and-half;

    @include mq(s) {
      margin-top: #{$space * 15};
    }
    @include mq(xl) {
      padding-bottom: #{$space-double * 2};
      flex-direction: row-reverse;
    }
    @include mq(2xl) {
      margin-top: #{$space * 17};
      padding-top: #{$space-double * 3};
      padding-bottom: #{$space-double * 2};
      flex-direction: row-reverse;
    }
  }

  &__banner-container {
    margin-top: #{$space*2};
    padding-right: 0px;
    position: relative;
    display: flex;
    flex-flow: wrap-reverse;
    flex-direction: column;
    margin-right: 0px;
    margin-left: auto;
    margin-bottom: -85px;

    @include mq(2xl) {
    position: absolute;
    display: flex;
    flex-flow: wrap-reverse;
    flex-direction: column;
    margin-right: 0px;
    margin-left: auto;
    top: -80%;
    }
  }

  &__banner {
    position: relative;
    margin-bottom: $space;
    z-index: 1;

    @include mq(m) {
      min-width: 200px;
      max-width: 400px;
    }
    @include mq(xl) {
      min-width: 400px;
      max-height: 200px;
    }
  }

  &__image {
    height: 300px;
    top: -592px;
    left: $space;
    right: $space;
    position: absolute;
    @include mq(s) {
      top: -600px;
    }
    @include mq(m) {
      top: -530px;
    }
    @include mq(2xl) {
      top: -360px;
    }
  }

  &__links {
    overflow-x: scroll;
    @include mq(xl) {
      flex-wrap: wrap;
      overflow-x: inherit;
      align-content: flex-end;
    }
  }

  &__link {
    padding: $space;

    @include mq(s) {
      padding: $space $space $space 0;
    }
    @include mq(m) {
      padding: $space;
    }
    @include mq(l) {
      padding: $space;
    }


    &:first-child {
      padding-left: 0;
    }
  }

}
