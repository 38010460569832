input, textarea {
	width: 100%;
  padding: $space;
  font-family: $font-bold;
  font-size: $font-size--small;
  color: $color-grey;

	&:focus {
		border-bottom-color: $color-orange;
	}
}

input {
  font-family: $font-bold;
  font-size: $font-size--small;
  color: $color-grey;

	&:focus {
	border-bottom-color: $color-orange;
	}
}

::placeholder {
  font-family: $font-family--primary;
  color:$color-placeholder;
  font-size: $font-size--small;
}

textarea {

	&.l-big {
		height: $textarea-big;
	}
}

.form-contact {
	text-align: center;
	padding: 0;
	padding-bottom: $space-double;

	& > input, & > textarea {
		border-left: 0px;
		border-top: 0px;
		border-right: 0px;
	}

	.btn {
		margin-top: $space-double;
	}
}
.form-contact-note {
	text-align: left;
	padding-left: $space;
	color: $color-grey--medium;
}

.form-contact-btn {
	background-color: transparent;
  	border: $space-half/4 solid;
  	color: $color-background-border--primary;
		&:hover {
		background-color: $color-background-button--primary;
		color: $color-text--secondary;
			&:active {
			background-color: darken($color-background-button--primary, 10%);
	 		}
		}
 }
