%link {
	color: $color-orange;
	text-decoration: none;
  &:hover {
    cursor: pointer;
  }
}
a {
	@extend %link;
}

.link__email {
	@extend %link;
}

.link__text {
  @extend %link;
  &:hover {
    text-decoration: underline;
  }
}
