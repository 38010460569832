
/*****************************
 * Cachebuster Configuration
 ****************************/
$cacheBusterKey: random(999999999999999);
$cachebuster: "c=#{$cacheBusterKey}";

$page-width: 1280px;
$page-width-slim: 1140px;
$page-width-l-940: 940px;
$page-width-half: 640px;

/*****************************
 * Breakpoint List
 ****************************/
$breakpoints: (
  s:      320px,
  s-370:  370px,
  m:      480px,
  l:      768px,
  l-940:  940px,
  xl:     1024px,
  2xl:    1140px,
  3xl:    1280px,
  4xl:    1440px,
);

$orientations: (
  t: 'top',
  r: 'right',
  b: 'bottom',
  l: 'left'
);

/*****************************
 * Transition List
 ****************************/
 $overlay-transition-time: 0.3s;
