.c-slider {
  border-left: $space solid white;
  border-right: $space solid white;

  &__slides {
    margin: 0;
    padding: 0;
    overflow-x: hidden;
    position: relative;
  }

  &__slide {
    position: absolute;
    min-height: 100px;
    transition: 0.6s ease;
    transform: translate(-100%, 0);
    visibility: hidden;
    width: 100%;

    &:last-child {
      position: relative;
      visibility: hidden;
    }

    &.active {
      transform: translate(0, 0);
      ~ .c-slider__slide {
        transform: translate(100%, 0);
      }
    }
  }
}
.slider {
  overflow-x: hidden;
  border-right: $space-double solid white;
  border-left: $space-double solid white;
}

.slider__index {
  vertical-align: top;
}

.slider__arrow:hover {
  color: $color-text--tetriary;
}

.slider__arrow--l {
  font-size: 30px;
  left: 0;
  margin: auto;
  position: absolute;
  transform: translateY(-50%);
  z-index: 1;
  top: 50%;

  &:hover {
    color: $color-orange;
  }
}

.slider__arrow--r {
  font-size: 30px;
  right: 0;
  left: auto;
  position: absolute;
  transform: translateY(-50%);
  z-index: 1;
  top: 50%;

  &:hover {
    color: $color-orange;
  }
}

.slider__elements {
  position: relative;
  left: 0;
  width: 1200%;
  margin-bottom: 0.8em;
  padding: 0;
  -webkit-transition: left 0.8s ease-in-out;
  -moz-transition: left 0.8s ease-in-out;
  -o-transition: left 0.8s ease-in-out;
  transition: left 0.8s ease-in-out;
}

.slider__elements-item {
  float: left;
  width: 8.333333%;
  position: relative;
}

@for $i from 1 through 8 {
  #slide__#{$i}:checked ~ .slider__elements {
    left: 0% + ($i - 1) * 100 * -1;
  }
  #slide__#{$i}:checked ~ .slider__nav .slide__label {
    display: none;
  }
  #slide__#{$i}:checked ~ .slider__nav .slide__label__#{$i} {
    display: block;
  }
}

.slider input {
  position: absolute;
  left: -99999px;
}

.slider__nav-item {
  line-height: $space;
  vertical-align: top;
}

.slider__nav-arrow {
  cursor: pointer;
  &:hover {
    color: $color-orange;
  }
}

.c-jobs-slide-size {
  min-height: 150px;
  min-width: auto;

  @include mq(l) {
    min-height: 150px;
    min-width: 550px;
  }
}

.c-jobs-slide-position {
  position: absolute;
  margin: 0 auto;
  transform: translateY(-20%);
  left: 0px;
  right: 0px;

  @include mq(m) {
    transform: translateY(-25%);
  }

  @include mq(l) {
    position: absolute;
    left: unset;
    right: 120px;
  }
}

.button_element {
  & :first-child {
    position: relative;
    overflow: hidden;
    transition: transform 0.2s;
  }
}

.button_element:hover {
  & :first-child {
    cursor: pointer;
    position: absolute;
    transform: scale(1.1);
  }
}
