/*****************************
 * Spacing
 ****************************/

$space-min:             4px;
$space-half:            8px;
$space:                 16px;
$space-and-half:        24px;
$space-double:          32px;
$space-triple:          48px;
$space-quadruple:       64px;

$headline-break--width: 50px;

$spacelist: (
    '0':                0,
    '4':                $space-min,
    '8':                $space-half,
    '16':               $space,
    '24':               $space-and-half,
    '32':               $space-double,
    '48':               $space-triple,
    '64':               $space-quadruple
);

$textarea-big :         250px;
