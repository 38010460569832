.l-flex {
    display: flex;
}

.l-inline-flex {
    display: inline-flex;
}

.l-flex-auto {
    -ms-flex: 1 1 100%;
    flex: 1 1 auto;
    min-width: 0;
    min-height: 0;
}

.l-flex-none {
    flex: none;
}

.l-flex-column {
    flex-direction: column;
}

.l-flex-row {
    flex-direction: row;
}

.l-flex-wrap {
    flex-wrap: wrap;
}

.l-flex-nowrap {
  flex-wrap: nowrap;
}

.l-items-start {
    align-items: flex-start;
}

.l-items-end {
    align-items: flex-end;
}

.l-items-center {
    align-items: center;
}

.l-items-baseline {
    align-items: baseline;
}

.l-items-stretch {
    align-items: stretch;
}

.l-self-start {
    align-self: flex-start;
}

.l-self-end {
    align-self: flex-end;
}

.l-self-center {
    align-self: center;
}

.l-self-baseline {
    align-self: baseline;
}

.l-self-stretch {
    align-self: stretch;
}

.l-justify-start {
    justify-content: flex-start;
}

.l-justify-end {
    justify-content: flex-end;
}

.l-justify-center {
    justify-content: center;
}

.l-justify-between {
    justify-content: space-between;
}

.l-justify-evenly {
    justify-content: space-evenly;
}

.l-justify-around {
    justify-content: space-around;
}

.l-content-start {
    align-content: flex-start;
}

.l-content-end {
    align-content: flex-end;
}

.l-content-center {
    align-content: center;
}

.l-content-between {
    align-content: space-between;
}

.l-content-around {
    align-content: space-around;
}

.l-content-stretch {
    align-content: stretch;
}
