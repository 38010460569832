.u-visible {
  display: inherit !important;
}
.u-hidden {
  display: none !important;
}

@each $b, $value in $breakpoints {
  @include mqMax($b) {
    .u-visible\@#{$b} {
      display: none !important;
    }
  }
}

@each $b, $value in $breakpoints {
  @include mq($b) {
    .u-hidden\@#{$b} {
      display: none !important;
    }
  }
}


.no-pointer-events {
  pointer-events: none;
}

