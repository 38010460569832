/*****************************
 * Basic Colors
 ****************************/
$color-black:                             #000000;
$color-white:                             #ffffff;
$color-grey:                              #4f4f4e;
$color-grey--dark:                        #474747;
$color-grey--medium:                      #999999;
$color-grey--light:                       #efefef;
$color-orange:                            #ff9933;
$color-orange--light:                     #f7a941;
$color-orange--dark:                      #ca6500;
$color-green:                             #99cc00;
$color-green--light:                      #afcb37;
$color-green--dark:                       #779e00;

/*****************************
 * Default Colors
 ****************************/
$color-shadow :                           #e3e3e3;
$color-border--primary:                   $color-green--light;
$color-overlay--primary:                  $color-green--light;
$color-border--secondary:                 $color-orange--light;
$color-placeholder:                       $color-grey;
$color-transparency:                      rgba(0,0,0,0);

/*****************************
 * Text Colors
 ****************************/
$color-text--primary:                     $color-grey;
$color-text--secondary:                   $color-white;
$color-text--tetriary:                    $color-orange;
$color-text--quaternary:                  $color-green--light;
$color-text--quinary:                     $color-green;
$color-text-disabled:                     lighten($color-grey, 50%);

/*****************************
 * Background Colors
 ****************************/
$color-background--primary:               $color-white;
$color-background--secondary:             $color-grey--light;
$color-background--tetriary:              $color-grey--dark;
$color-background--quaternary:            $color-orange;
$color-background--transparent:           rgba(239, 239, 239, 0.9);
/*****************************
 * Link Colors
 ****************************/
$color-link--primary:                     $color-text--primary;
$color-link--secondary:                   $color-orange--dark;
$color-link--tetriary:                    $color-grey--light;
$color-link--quaternary:                  $color-orange--light;
$color-link--quinary:                     $color-green--dark;
$color-link--senary:                      $color-grey--dark;

/*****************************
 * Button Colors
 ****************************/
$color-button--default:                   $color-grey--dark;
$color-button--primary:                   $color-orange;
$color-button--secondary:                 $color-green;
$color-background-button--default:        $color-grey--light;
$color-background-button--primary:        $color-orange;
$color-background-button--secondary:      $color-green;
$color-background-border--default:        $color-grey--light;
$color-background-border--primary:        $color-orange;
$color-background-border--secondary:      $color-green;

/*****************************
 * Border Colors
 ****************************/
$color-border--primary:                   $color-grey;
$color-border--secondary:                 $color-grey--dark;
$color-border--tetriary:                  $color-white;

/*****************************
 * Shadow Colors
 ****************************/
$color-shadow--primary:                   #dedede;
$color-shadow--secondary:                 rgba(0, 0, 0, .16);

/*****************************
 * Gradient Colors
 ****************************/
 $color-gradient--primary:                $color-grey--medium;
 $color-gradient--secondary:            rgba(71, 71, 71, 1);

/*****************************
 * Element Colors
 ****************************/
$color--valid:                            $color-green;
$color--invalid:                          $color-orange;
$color--selected:                         $color-green;

