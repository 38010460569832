// ==========================================================================
// $susy
// ==========================================================================
@import 'susy';

// ==========================================================================
// $override susy defaults
// ==========================================================================
$_susy-defaults: (
  'columns': susy-repeat(12),
  'gutters': .25,
  'spread': 'wide',
  'container-spread': 'wide',
);
