@font-face {
  font-family: "IconFont";
  src: url('../fonts/icons/IconFont.eot?#{$cachebuster}');
  src: url('../fonts/icons/IconFont.eot?#{$cachebuster}#iefix') format('eot'),
    url('../fonts/icons/IconFont.woff2?#{$cachebuster}') format('woff2'),
    url('../fonts/icons/IconFont.woff?#{$cachebuster}') format('woff'),
    url('../fonts/icons/IconFont.ttf?#{$cachebuster}') format('truetype'),
    url('../fonts/icons/IconFont.svg?#{$cachebuster}#IconFont') format('svg');
}

@mixin icon-styles {
  font-family: "IconFont";
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  // speak: none; // only necessary if not using the private unicode range (firstGlyph option)
  text-decoration: none;
  text-transform: none;
}

%icon {
  @include icon-styles;
}

@function icon-char($filename) {
  $char: "";

  @if $filename == add-negative {
    $char: "\E001";
  }
  @if $filename == add {
    $char: "\E002";
  }
  @if $filename == arrow-down {
    $char: "\E003";
  }
  @if $filename == arrow-left {
    $char: "\E004";
  }
  @if $filename == arrow-right {
    $char: "\E005";
  }
  @if $filename == arrow-up {
    $char: "\E006";
  }
  @if $filename == arrow {
    $char: "\E007";
  }
  @if $filename == calendar {
    $char: "\E008";
  }
  @if $filename == check {
    $char: "\E009";
  }
  @if $filename == close-2 {
    $char: "\E00A";
  }
  @if $filename == close-negative {
    $char: "\E00B";
  }
  @if $filename == close {
    $char: "\E00C";
  }
  @if $filename == e-mail {
    $char: "\E00D";
  }
  @if $filename == filter {
    $char: "\E00E";
  }
  @if $filename == location-negative {
    $char: "\E00F";
  }
  @if $filename == location {
    $char: "\E010";
  }
  @if $filename == menu-mobile {
    $char: "\E011";
  }
  @if $filename == ranking {
    $char: "\E012";
  }
  @if $filename == scroll-down {
    $char: "\E013";
  }
  @if $filename == search-regular {
    $char: "\E014";
  }
  @if $filename == search-solid {
    $char: "\E015";
  }
  @if $filename == search {
    $char: "\E016";
  }
  @if $filename == telephone {
    $char: "\E017";
  }

  @return $char;
}

@mixin icon($filename, $insert: before, $extend: true) {
  &:#{$insert} {
    @if $extend {
      @extend %icon;
    } @else {
      @include icon-styles;
    }
    content: icon-char($filename);
  }
}

.icon-add-negative {
  @include icon(add-negative);
}
.icon-add {
  @include icon(add);
}
.icon-arrow-down {
  @include icon(arrow-down);
}
.icon-arrow-left {
  @include icon(arrow-left);
}
.icon-arrow-right {
  @include icon(arrow-right);
}
.icon-arrow-up {
  @include icon(arrow-up);
}
.icon-arrow {
  @include icon(arrow);
}
.icon-calendar {
  @include icon(calendar);
}
.icon-check {
  @include icon(check);
}
.icon-close-2 {
  @include icon(close-2);
}
.icon-close-negative {
  @include icon(close-negative);
}
.icon-close {
  @include icon(close);
}
.icon-e-mail {
  @include icon(e-mail);
}
.icon-filter {
  @include icon(filter);
}
.icon-location-negative {
  @include icon(location-negative);
}
.icon-location {
  @include icon(location);
}
.icon-menu-mobile {
  @include icon(menu-mobile);
}
.icon-ranking {
  @include icon(ranking);
}
.icon-scroll-down {
  @include icon(scroll-down);
}
.icon-search-regular {
  @include icon(search-regular);
}
.icon-search-solid {
  @include icon(search-solid);
}
.icon-search {
  @include icon(search);
}
.icon-telephone {
  @include icon(telephone);
}